<template>
  <div>
    <v-form
      v-model="validation"
      ref="form"
      @submit.prevent="title === 'Crear' ? createProduct() : editProduct()"
    >
      <v-container>
        <Title :title="title + ' producto o servicio'" class="mb-10" />
        <v-row>
          <v-col cols="12" md="6">
            <v-select
              v-model="form.location"
              :items="locations"
              label="Sede *"
              item-value="id"
              :item-text="(value) => value.name"
              :rules="rules.required"
              outlined
            ></v-select>
          </v-col>
          <v-col cols="12" md="6">
            <v-select
              v-model="form.type"
              :rules="rules.required"
              :items="[
                { text: 'Producto', value: 'product' },
                { text: 'Servicio', value: 'service' },
              ]"
              label="Tipo *"
              outlined
            ></v-select>
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field
              v-model="form.name"
              type="text"
              label="Nombre *"
              :rules="rules.default"
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-select
              v-model="form.currency"
              :items="currenciesOptions"
              label="Moneda"
              outlined
            ></v-select>
          </v-col>
          <v-col v-if="form.currency !== 'DOP'" cols="12" md="6">
            <vuetify-money
              v-model="foreignCost"
              label="Costo"
              placeholder="0.00"
              :outlined="true"
              :clearable="true"
              :backgroundColor="
                $vuetify.theme.currentTheme.vuetifyMoneyBackground
              "
              :options="{
                locale: 'ja-JP',
                prefix: currentCurrencyPrefix + ' ',
                suffix: '',
                length: 9,
                precision: 2,
              }"
              :properties="{
                hint: '',
              }"
            />
          </v-col>
          <v-col v-if="form.currency !== 'DOP'" cols="12" md="6">
            <vuetify-money
              v-model="foreignPrice"
              label="Precio de venta"
              placeholder="0.00"
              :outlined="true"
              :clearable="true"
              :backgroundColor="
                $vuetify.theme.currentTheme.vuetifyMoneyBackground
              "
              :options="{
                locale: 'ja-JP',
                prefix: currentCurrencyPrefix + ' ',
                suffix: '',
                length: 9,
                precision: 2,
              }"
              :properties="{
                hint: '',
              }"
            />
          </v-col>
          <v-col v-if="form.currency == 'DOP'" cols="12" md="6">
            <vuetify-money
              v-model="form.cost"
              label="Costo"
              placeholder="0.00"
              :outlined="true"
              :clearable="true"
              :backgroundColor="
                $vuetify.theme.currentTheme.vuetifyMoneyBackground
              "
              :options="{
                locale: 'ja-JP',
                prefix: currentCurrencyPrefix + ' ',
                suffix: '',
                length: 9,
                precision: 2,
              }"
              :properties="{
                hint: '',
              }"
            />
          </v-col>
          <v-col v-if="form.currency == 'DOP'" cols="12" md="6">
            <vuetify-money
              v-model="form.price"
              label="Precio de venta"
              placeholder="0.00"
              :outlined="true"
              :clearable="true"
              :backgroundColor="
                $vuetify.theme.currentTheme.vuetifyMoneyBackground
              "
              :options="{
                locale: 'ja-JP',
                prefix: currentCurrencyPrefix + ' ',
                suffix: '',
                length: 9,
                precision: 2,
              }"
              :properties="{
                hint: '',
              }"
            />
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field
              v-model="form.discount"
              type="number"
              label="Máximo descuento aplicable"
              append-icon="mdi-percent"
              outlined
            ></v-text-field>
          </v-col>

          <v-col v-if="form.type == 'product'" cols="12" md="6">
            <v-text-field
              v-model="form.stock"
              type="number"
              label="Stock"
              outlined
            ></v-text-field>
          </v-col>
          <v-col v-if="form.type == 'product'" cols="12" md="6">
            <v-text-field
              v-model="form.stockAlert"
              type="number"
              label="Stock de referencia para agotamiento"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn
              depressed
              small
              color="primary"
              class="pa-5"
              type="submit"
              :disabled="!validation"
              :loading="loading"
            >
              {{ title }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
import axios from "axios";
import Title from "../Title.vue";
import { formatFloat } from "../../helpers/formatters";

export default {
  name: "ProductForm",
  components: {
    Title,
  },
  props: ["title", "product"],
  data() {
    return {
      form: {
        name: "",
        cost: "",
        price: "",
        discount: "",
        type: "",
        stock: "",
        stockAlert: "",
        location: "",
        currency: "DOP",
      },
      foreignCost: "",
      foreignPrice: "",
      rules: {
        default: [
          (v) => !!v || "Campo obligatorio",
          (v) => (v && v.length <= 100) || "Debe tener menos de 100 caracteres",
        ],
        required: [(v) => !!v || "Campo obligatorio"],
      },
      validation: true,
      loading: false,
    };
  },
  computed: {
    ...mapGetters([
      "currentUser",
      "currentLocation",
      "locations",
      "availableCurrencies",
      "currencies",
    ]),
    currentCurrencyPrefix() {
      return this.availableCurrencies.filter(
        (c) => c.value == this.form.currency
      )[0].prefix;
    },
    currentCurrencyRate() {
      return this.currencyRate
        ? Number(this.currencyRate)
        : Number(
            this.currencies.filter((c) => c.code == this.form.currency)[0]
              ?.rate || 1
          );
    },
    currenciesOptions() {
      const options =
        this.currencies
          ?.filter((c) => c.value !== "DOP")
          .map((c) => {
            const text = this.availableCurrencies.filter(
              (ac) => ac.value == c.code
            )[0].text;
            return {
              text,
              value: c.code,
            };
          }) || [];

      return [
        {
          text: "Peso dominicano",
          value: "DOP",
        },
        ...options,
      ];
    },
  },
  methods: {
    ...mapMutations(["pushProduct", "setAlert", "updateProduct"]),
    async createProduct() {
      try {
        this.loading = true;
        const res = await axios.post("/api/product", {
          ...this.form,
          doctorId: this.currentUser.doctor.id,
        });
        const { item, message } = res.data;
        this.pushProduct(item);
        this.setAlert({
          show: true,
          color: "success",
          icon: "mdi-check-circle",
          timeout: 4000,
          text: message,
        });
        this.$refs.form.reset();
        this.$emit("product-created", item);
      } catch (error) {
        console.log(error);
        this.setAlert({
          show: true,
          color: "error",
          icon: "mdi-alert-remove",
          text: error.response.data.message,
        });
      } finally {
        this.loading = false;
      }
    },
    async editProduct() {
      try {
        this.loading = true;
        const res = await axios.put(`/api/product/${this.product.id}`, {
          id: this.product.id,
          ...this.form,
        });
        const { item, message } = res.data;
        this.setAlert({
          show: true,
          color: "success",
          icon: "mdi-check-circle",
          timeout: 4000,
          text: message,
        });
        this.updateProduct(item);
        this.$emit("product-updated", item);
      } catch (error) {
        console.log(error);
        this.setAlert({
          show: true,
          color: "error",
          icon: "mdi-alert-remove",
          text: error.response.data.message,
        });
      } finally {
        this.loading = false;
      }
    },
    cancel() {
      this.$emit("on-cancel");
    },
  },
  watch: {
    foreignCost: {
      handler(val) {
        this.form.cost = formatFloat(Number(val) * this.currentCurrencyRate);
      },
    },
    foreignPrice: {
      handler(val) {
        this.form.price = formatFloat(Number(val) * this.currentCurrencyRate);
      },
    },
    product: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          Object.assign(this.form, newVal);
        }

        if (this.title === "Crear") {
          this.$refs.form?.reset();
          this.form.price = 0;
          this.form.cost = 0;
          this.form.discount = 0;
          this.form.currency = "DOP";
          this.form.type = "service";
          this.form.location = this.currentLocation?.id;
        }
      },
    },
  },
};
</script>
